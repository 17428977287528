import clsx from "clsx";
import { AllHTMLAttributes, createElement, ElementType, forwardRef } from "react";
import { atoms, Atoms, extractAtomsProps } from "../../../core/atom";

export type BoxProps = {
  as?: ElementType;
  className?: string;
} & AllHTMLAttributes<HTMLElement> &
  Omit<Atoms, "reset">;

export const Box = forwardRef<HTMLElement, BoxProps>(({ as = "div", className, ...delegated }, ref) => {
  const { atomProps, nativeProps } = extractAtomsProps(delegated);
  const atomicClasses = atoms({
    reset: as as never,
    ...atomProps,
  });
  return createElement(as, {
    className: clsx(atomicClasses, className),
    ...nativeProps,
    ref,
  });
});
