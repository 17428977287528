import * as React from "react";
import styled from "styled-components";
import { StretchSpacer } from "../../gls/spacer";
import { SocialLinks } from "../common/icon-link";
import { PageLinks } from "../page/page.data";
import { HeaderHeadroom } from "./header-headroom";
import { HeaderLogoLink } from "./header-logo";
import { Sidebar, SidebarToggle } from "./sidebar";
import { SidebarCloseButton, SidebarContent, SidebarLink } from "./sidebar-content";

export const PageHeader = () => {
  const [isSidebarVisible, setIsSideBarVisible] = React.useState(false);
  return (
    <>
      <Header>
        <HeaderHeadroom>
          <HeaderLogoLink />
          <SidebarToggle onClick={() => setIsSideBarVisible(true)} />
        </HeaderHeadroom>
        <Sidebar isOpen={isSidebarVisible} onStateChange={({ isOpen }) => setIsSideBarVisible(isOpen)}>
          <SidebarContent>
            <SidebarCloseButton onClick={() => setIsSideBarVisible(false)} />
            <SidebarLink to={PageLinks.home} onClick={() => setIsSideBarVisible(false)}>
              Home
            </SidebarLink>
            <SidebarLink to={PageLinks.aboutUs} onClick={() => setIsSideBarVisible(false)}>
              About Us
            </SidebarLink>
            <SidebarLink to={PageLinks.hydrogenForMobility} onClick={() => setIsSideBarVisible(false)}>
              Hydrogen For Mobility
            </SidebarLink>
            <SidebarLink to={PageLinks.circle} onClick={() => setIsSideBarVisible(false)}>
              H_Circle
            </SidebarLink>
            <SidebarLink to={PageLinks.designLab} onClick={() => setIsSideBarVisible(false)}>
              Design Lab
            </SidebarLink>
            <SidebarLink to={PageLinks.team} onClick={() => setIsSideBarVisible(false)}>
              Our Team
            </SidebarLink>
            <SidebarLink to={PageLinks.contactUs} onClick={() => setIsSideBarVisible(false)}>
              Contact
            </SidebarLink>
            <StretchSpacer />
            <SocialLinks />
          </SidebarContent>
        </Sidebar>
      </Header>
    </>
  );
};

const Header = styled.header({
  width: "100%",
});
