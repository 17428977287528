import { mapResponsiveValue, OptionalResponsiveValue } from "../sprinkles.css";

export type AlignX = "left" | "center" | "right";
export type AlignY = "top" | "center" | "bottom";

const alignToFlexAlignLookup = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
} as const;

export const alignXToFlexAlign = (align: OptionalResponsiveValue<AlignX> | undefined) =>
  align ? mapResponsiveValue(align, (value) => alignToFlexAlignLookup[value]) : undefined;

const alignYToFlexAlignLookup = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
} as const;

export const alignYToFlexAlign = (alignY: OptionalResponsiveValue<AlignY> | undefined) =>
  alignY ? mapResponsiveValue(alignY, (value) => alignYToFlexAlignLookup[value]) : undefined;
