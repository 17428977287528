import clsx from "clsx";
import * as React from "react";
import { atoms, Atoms, extractAtomsProps } from "../../../core/atom";
import { link, textDecoration } from "./link.css";

type BaseProps = {
  size?: Atoms["fontSize"];
  underline?: boolean;
  colorScheme?: "primary" | "white";
} & Omit<Atoms, "fontSize" | "color">;

type LinkProps<C extends React.ElementType> = {
  as?: C;
  children: React.ReactNode;
} & React.ComponentPropsWithoutRef<C> &
  BaseProps;

export const Link = <C extends React.ElementType = "a">(props: LinkProps<C>) => {
  const {
    as,
    underline,
    colorScheme = "primary",
    size = "sm",
    fontFamily = "primary",
    fontWeight = "normal",
    className,
    children,
    ...delegated
  } = props;
  const { atomProps, nativeProps } = extractAtomsProps(delegated);
  const classes = clsx([
    atoms({ reset: "a", fontSize: size, fontFamily, ...atomProps }),
    link({ colorScheme: colorScheme }),
    textDecoration[underline ? "underline" : "none"],
    className,
  ]);
  const Component = as || "a";
  return (
    <Component className={classes} {...nativeProps}>
      {children}
    </Component>
  );
};
