import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { RichText, RichTextField } from "../../cms/RichText";
import { Page } from "../../components/templates/page/page";
import { Section } from "../../components/templates/page/section/section";
import { SectionHero, SectionHeroImageCss } from "../../components/templates/page/section/section-hero";
import { Link, Stack, Text } from "../../design-system";
import { FacebookCircleIcon, LinkedInCircleIcon, TwitterCircleIcon } from "../../design-system/components/icon/icon";

type Props = {
  slug: string;
  title: string;
  date: string;
  heroImage: IGatsbyImageData;
  description: RichTextField;
};

export const MediaCentreArticleTemplate = (props: Props) => {
  const { slug, title, heroImage, date, description } = props;
  return (
    <Page title={title}>
      <SectionHero
        title={title}
        image={<GatsbyImage image={heroImage} style={SectionHeroImageCss} objectFit={"cover"} alt="" />}
      />
      <Section>
        <Stack space={["sm"]} mb={"lg"}>
          <Text fontWeight={"bold"}>{date}</Text>
          <SocialMediaLinks slug={slug} />
        </Stack>
        <RichText field={description} />
      </Section>
    </Page>
  );
};

const SocialMediaLinks = (props: { slug: string }) => {
  const isBrowser = typeof window !== `undefined`;
  const url = isBrowser ? `${window.location.origin}/media-centre/${props.slug}` : "";
  return (
    <Stack direction={"row"} space={"xs"}>
      <Link href={`https://www.facebook.com/sharer.php?u=${url}`} target={"_blank"}>
        <FacebookCircleIcon size={32} isActionable />
      </Link>
      <Link href={`https://twitter.com/share?url=${url}`} target={"_blank"}>
        <TwitterCircleIcon size={32} isActionable />
      </Link>
      <Link href={`https://www.linkedin.com/shareArticle/?mini=true&url=${url}`} target={"_blank"}>
        <LinkedInCircleIcon size={32} isActionable />
      </Link>
    </Stack>
  );
};
