import React from "react";
import Headroom from "react-headroom";
import styled from "styled-components";
import { Theme } from "../../core/app.theme";

export const HeaderHeadroom = (props: { children: React.ReactNode }) => {
  const [onHeaderUp, setOnHeaderUp] = React.useState(false);
  return (
    <Wrapper
      style={
        onHeaderUp
          ? {
              backgroundColor: "#475257",
              transition: "all .4s ease-in-out",
              opacity: "0.8",
            }
          : {}
      }
      onPin={() => setOnHeaderUp(true)}
      onUnfix={() => setOnHeaderUp(false)}
    >
      <Content>{props.children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled(Headroom)({
  backgroundColor: "transparent",
  position: "absolute",
  width: "100%",
  zIndex: 3,
});

const Content = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: Theme.sizeContainer("lg"),
  margin: "0 auto",
  padding: `${Theme.space("sm")} ${Theme.space("md")}`,
});
