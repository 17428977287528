// @ts-ignore
import * as React from "react";
import styled from "styled-components";
// @ts-ignore
import aiGroup from "../../../assets/images/partner/ai-group.webp";
// @ts-ignore
import atlasCopco from "../../../assets/images/partner/atlas-copco.png";
// @ts-ignore
import claytonH2 from "../../../assets/images/partner/clayton-h2.png";
// @ts-ignore
import convoloDesign from "../../../assets/images/partner/convolo-design.png";
// @ts-ignore
import foton from "../../../assets/images/partner/foton.png";
// @ts-ignore
import ghs from "../../../assets/images/partner/ghs.png";
// @ts-ignore
import hscaled from "../../../assets/images/partner/h_scaled.png";
// @ts-ignore
import haskel from "../../../assets/images/partner/haskel.png";
// @ts-ignore
import indro from "../../../assets/images/partner/indro.png";
// @ts-ignore
import nera from "../../../assets/images/partner/nera.png";
// @ts-ignore
import nilson from "../../../assets/images/partner/nilson.png";
// @ts-ignore
import plugPower from "../../../assets/images/partner/plug-power.png";
// @ts-ignore
import rc from "../../../assets/images/partner/rc.png";
// @ts-ignore
import skai from "../../../assets/images/partner/skai.png";
// @ts-ignore
import tfa from "../../../assets/images/partner/tfa.png";
// @ts-ignore
import umoe from "../../../assets/images/partner/umoe.png";
// @ts-ignore
import voltFarmer from "../../../assets/images/partner/volt-farmer.png";
import { Theme } from "../../core/app.theme";

export const PartnerLogoAIGroup = () => (
  <PartnerLink href={"https://www.aigroup.com.au/"}>
    <PartnerLogo src={aiGroup} alt={"AI Group"} />
  </PartnerLink>
);

export const PartnerLogoGreenHydrogen = () => (
  <PartnerLink href={"https://greenhydrogen.dk"}>
    <PartnerLogo src={ghs} alt={"Green Hydrogen System"} />
  </PartnerLink>
);
export const PartnerLogoHScaled = () => (
  <PartnerLink href={"https://www.hscaled.com"}>
    <PartnerLogo src={hscaled} alt={"H Scaled"} />
  </PartnerLink>
);
export const PartnerLogoIndro = () => (
  <PartnerLink href={"http://www.idromeccanica.it"}>
    <PartnerLogo src={indro} alt={"Indro"} />
  </PartnerLink>
);

export const PartnerLogoNilson = () => (
  <PartnerLink href={"https://nilssonenergy.com"}>
    <PartnerLogo src={nilson} alt={"Nilson"} />
  </PartnerLink>
);

export const PartnerLogoPlugPower = () => (
  <PartnerLink href={"https://www.plugpower.com"}>
    <PartnerLogo src={plugPower} alt={"Plug Power"} />
  </PartnerLink>
);
export const PartnerLogoRC = () => (
  <PartnerLink href={"https://rosannaceravolo.com"}>
    <PartnerLogo src={rc} alt={"Rosanna Ceravolo"} />
  </PartnerLink>
);
export const PartnerLogoSkai = () => (
  <PartnerLink href={"https://www.skaienergies.com"}>
    <PartnerLogo src={skai} alt={"Skai"} />
  </PartnerLink>
);
export const PartnerLogoTfa = () => (
  <PartnerLink href={"https://www.tfa.com.au"}>
    <PartnerLogo src={tfa} alt={"Tfa"} />
  </PartnerLink>
);
export const PartnerLogoUMOE = () => (
  <PartnerLink href={"http://umoe.no/"}>
    <PartnerLogo src={umoe} alt={"UMOE"} />
  </PartnerLink>
);
export const PartnerLogoVoltFarmers = () => (
  <PartnerLink href={"https://voltfarmer.com.au"}>
    <PartnerLogo src={voltFarmer} alt={"Volt Farmers"} />
  </PartnerLink>
);
export const PartnerAtlasCopco = () => (
  <PartnerLink href={"https://www.atlascopco.com"}>
    <PartnerLogo src={atlasCopco} alt={"Atlas Copco"} />
  </PartnerLink>
);
export const PartnerFoton = () => (
  <PartnerLink href={"https://fotonmotor.com.au"}>
    <PartnerLogo src={foton} alt={"Foton Mobility"} />
  </PartnerLink>
);
export const PartnerNera = () => (
  <PartnerLink href={"https://www.nera.org.au"}>
    <PartnerLogo src={nera} alt={"National Energy Resources Australia"} />
  </PartnerLink>
);

export const PartnerConvolo = () => (
  <PartnerLink href={"https://www.convolodesign.com/"}>
    <PartnerLogo src={convoloDesign} alt={"Convolo Design"} />
  </PartnerLink>
);

export const PartnerClaytonH2 = () => (
  <PartnerLink href={"https://claytonh2.org/"}>
    <PartnerLogo src={claytonH2} alt={"Clayton H2"} />
  </PartnerLink>
);

export const PartnersHorizontal = styled.div({
  display: "inline-flex",
  width: "100%",
  whiteSpace: "nowrap",
  scrollBehavior: "smooth",
  overflowX: "auto",
  overflowY: "hidden",
  "::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  scrollbarWidth: "none",
  [Theme.media("lg")]: {
    alignItems: "center",
    justifyContent: "center",
  },
});

const PartnerLink = styled.a.attrs({ target: "blank" })({});
const PartnerLogo = styled.img({
  height: 50,
  [Theme.media("lg")]: {
    height: 60,
  },
});
