import * as React from "react";
import styled from "styled-components";
import { Heading } from "../../../atoms/typography";
import { Theme } from "../../../core/app.theme";

export const SectionHero = (props: { image: React.ReactNode; title: string }) => (
  <FullBleedHeaderContainer>
    {props.image}
    <FullBleedHeaderContentLayout>
      <Heading
        as={"h1"}
        fontFamily={"body"}
        fontSize={"3xl"}
        fontWeight={"bold"}
        colorScheme={"ghostgumwhite"}
        maxWidth={700}
        lineHeight={"shorter"}
      >
        {props.title}
      </Heading>
    </FullBleedHeaderContentLayout>
  </FullBleedHeaderContainer>
);

export const SectionHeroImageCss = {
  gridArea: "1/1",
  minHeight: 350, //For mobile
  maxHeight: 450,
};

const FullBleedHeaderContainer = styled.section({
  display: "grid",
});

const FullBleedHeaderContentLayout = styled.div({
  display: "flex",
  gridArea: "1/1",

  position: "relative",
  alignItems: "center",
  justifyContent: "flex-start",

  margin: "0 auto",
  width: "100%",
  maxWidth: Theme.sizeContainer("lg"),
  padding: Theme.space("md"),
});
