import clsx from "clsx";
import * as React from "react";
import { ButtonHTMLAttributes } from "react";
import { Box, BoxProps } from "../box/box";
import { button, ButtonVariants } from "./button.css";

type Props = {} & ButtonVariants & BoxProps & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = (props: Props) => {
  const { colorScheme, children, className, ...delegated } = props;
  const classes = clsx([button({ colorScheme }), className]);
  return (
    <Box as={"button"} type={"button"} className={classes} {...delegated}>
      {children}
    </Box>
  );
};
