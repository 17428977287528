import * as React from "react";
import styled from "styled-components";
import { Theme } from "../core/app.theme";

/**
 * TODO Use user agent to determine layout
 */
export const ResponsiveLayout = (props: { mobile: () => React.ReactNode; desktop: () => React.ReactNode }) => (
  <>
    <DesktopOnly>{props.desktop()}</DesktopOnly>
    <MobileOnly>{props.mobile()}</MobileOnly>
  </>
);

export const DesktopOnly = styled.div({
  display: "none",
  [Theme.media("lg")]: {
    display: "block",
  },
});

export const MobileOnly = styled.div({
  display: "block",
  [Theme.media("lg")]: {
    display: "none",
  },
});
