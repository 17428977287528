import * as React from "react";
import styled from "styled-components";
import { theme, Theme } from "../../core/app.theme";
import { Vertical } from "../../gls/vertical";
import { useOnOutsideClick } from "../../hooks/common.hook";

export const SidebarToggle = (props: { onClick: () => any }) => (
  <SidebarButton style={{ position: "relative" }} aria-label="Open navigation" type={"button"} onClick={props.onClick}>
    <Vertical space={"2xs"}>
      <Line id={"line"} />
      <Line id={"line"} />
      <Line id={"line"} />
    </Vertical>
  </SidebarButton>
);

const Line = styled.div({
  width: "35px",
  height: "2px",
  backgroundColor: Theme.color("white"),
});

const SidebarButton = styled.button({
  border: "none",
  cursor: "pointer",
  display: "inline-block",
  background: "none",
  ":focus": {
    ["& #line"]: {
      backgroundColor: Theme.color("white700"),
    },
  },
  ":hover": {
    ["& #line"]: {
      backgroundColor: Theme.color("white700"),
    },
  },
});

interface SidebarProps {
  isOpen: boolean;
  onStateChange?: (state: { isOpen: boolean }) => void;
  children: React.ReactNode;
}

const zIndexOverlay = 100;
const zIndexRoot = 101;
const zIndexContent = 101;

export const Sidebar = ({ isOpen, onStateChange, children }: SidebarProps) => {
  const node = React.useRef<HTMLDivElement>(null);
  const onClose = React.useCallback(() => {
    onStateChange?.({ isOpen: false });
  }, []);

  useOnOutsideClick(node, onClose);
  return (
    <SidebarRoot data-comment="sidebar-root" isOpen={isOpen}>
      <SidebarContent data-comment="sidebar-content" ref={node} isOpen={isOpen} children={children} />
      <SidebarOverlay role="presentation" data-comment="sidebar-overlay" isOpen={isOpen} />
    </SidebarRoot>
  );
};

const SidebarRoot = styled.div<{ isOpen: boolean }>`
  z-index: ${zIndexRoot};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};
`;

const SidebarOverlay = styled.div<{ isOpen: boolean }>`
  z-index: ${zIndexOverlay};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.8);
`;

const SidebarContent = styled.div<{ isOpen: boolean }>`
  background: ${Theme.color("icywaters")};
  z-index: ${zIndexContent};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: transform 0.3s ease-out;
  overflow-y: auto;
  width: 300px;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0%)" : "translateX(-100%)")};
  @media (min-width: ${theme.breakpoints.md}px) {
    width: 350px;
  }
`;
