import styled from "styled-components";
import { Theme } from "../core/app.theme";

export const Button = styled.button<{ colorScheme?: Theme.ColorScheme }>(({ colorScheme = "primary" }) => ({
  ...buttonBaseCss,
  color: "white",
  backgroundColor: Theme.color(colorScheme),
  borderColor: "transparent",
  "&:focus:enabled": {
    backgroundColor: Theme.color(`${colorScheme}700`),
  },
  "&:hover:enabled": {
    backgroundColor: Theme.color(`${colorScheme}700`),
  },
  "&:active:enabled": {
    backgroundColor: Theme.color(`${colorScheme}700`),
  },
}));

export const buttonBaseCss = {
  cursor: "pointer",
  height: "36px",
  width: "auto",
  padding: "10px 12px 10px",
  fontFamily: Theme.fontFamily("monument"),
  fontSize: Theme.fontSize("xs"),
  fontWeight: Theme.fontWeight("thin"),
  borderRadius: Theme.radius("lg"),
  borderWidth: "1px",
  borderStyle: "solid",
  textDecoration: "none",
  outline: "none",
  transition: "color .4s, background-color .4s",
  "&:disabled": {
    color: "white",
    backgroundColor: Theme.color("grey400"),
    borderColor: Theme.color("grey"),
    cursor: "not-allowed",
  },
  "&:disabled:hover": {
    color: "white",
    backgroundColor: Theme.color("grey400"),
    borderColor: Theme.color("grey"),
    cursor: "not-allowed",
  },
};
