import styled from "styled-components";
import { Theme } from "../core/app.theme";

export type StretchProps = {
  space?: Theme.Space;
  sizing?: number;
};
/**
 * Spacers
 */
export const StretchSpacer = styled.div.attrs({
  "data-comment": "StretchSpacer",
})<StretchProps>`
  flex: ${(props) => props.sizing || 1};
`;

export const HorizontalSpacer = styled.div.attrs({
  "data-comment": "HorizontalSpacer",
})<{ space?: Theme.Space }>((props) => ({
  display: "inline-block",
  width: `${Theme.space(props.space ?? "md")} !important`,
}));

export const VerticalSpacer = styled.div.attrs({
  "data-comment": "VerticalSpacer",
})<{ space?: Theme.Space }>((props) => ({
  height: `${Theme.space(props.space ?? "md")} !important`,
}));

export const VerticalSpacerResponsive = styled.div.attrs({
  "data-comment": "VerticalSpacerResponsive",
})<{ space?: { sm?: Theme.Space; md?: Theme.Space; lg?: Theme.Space } }>((props) => ({
  height: `${Theme.space(props.space?.sm ?? "md")} !important`,
  [Theme.media("md")]: {
    height: `${Theme.space(props.space?.md ?? "md")} !important`,
  },
  [Theme.media("lg")]: {
    height: `${Theme.space(props.space?.lg ?? "lg")} !important`,
  },
}));

/**
 Containers:
 Content takes up as much space as needed its child.
 Stretch into the parent container.
 */
export const Content = styled.div.attrs({
  "data-comment": "Content",
})`
  flex-basis: auto;
`;
export const Stretch = styled.div.attrs({
  "data-comment": "Stretch",
})<{ sizing?: number }>`
  flex: ${(props) => props.sizing || 1};
`;
