import * as React from "react";
import { IconEmail, IconInstagram, IconLinkedIn } from "../../atoms/icon";
import { Horizontal } from "../../gls/horizontal";

export const SocialLinks = () => (
  <Horizontal space={"sm"}>
    <IconLinkedIn
      size={20}
      action={{
        as: "a",
        href: "https://www.linkedin.com/company/hydrogen-fuels-australia-pty-ltd",
        target: "_blank",
      }}
    />
    <IconInstagram
      size={20}
      action={{
        as: "a",
        href: "https://www.instagram.com/h2fau",
        target: "_blank",
      }}
    />
    <IconEmail
      size={20}
      viewBox={"0 0 24 18"}
      action={{
        as: "a",
        href: "mailto:info@h2fa.com.au",
        target: "_blank",
      }}
    />
  </Horizontal>
);
