import * as React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { Theme } from "../core/app.theme";

export type LinkProps = {
  fontSize?: Theme.FontSize;
  color?: Theme.ColorScheme;
  fontFamily?: Theme.FontFamily;
  fontWeight?: Theme.FontWeight;
  lineHeight?: Theme.LineHeight;
  letterSpacing?: Theme.LetterSpacing;
  textTransform?: React.CSSProperties["textTransform"];
  maxWidth?: React.CSSProperties["maxWidth"];
  textAlign?: React.CSSProperties["textAlign"];
  underline?: boolean;
  children?: React.ReactNode;
} & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const Link = styled.a<LinkProps>((props) => ({
  ...createLinkStyles({
    color: "icywaters",
    fontWeight: "bold",
    ...props,
  }),
}));

export type LinkInternalProps = {
  $fontSize?: Theme.FontSize;
  $color?: Theme.ColorScheme;
  $fontFamily?: Theme.FontFamily;
  $fontWeight?: Theme.FontWeight;
  $lineHeight?: Theme.LineHeight;
  $letterSpacing?: Theme.LetterSpacing;
  $textTransform?: React.CSSProperties["textTransform"];
  $maxWidth?: React.CSSProperties["maxWidth"];
  $textAlign?: React.CSSProperties["textAlign"];
  $underline?: boolean;
  children?: React.ReactNode;
} & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const LinkInternal = styled(GatsbyLink)<LinkInternalProps>((props) => ({
  ...createLinkStyles({
    color: props.$color ?? "icywaters",
    fontSize: props.$fontSize,
    fontFamily: props.$fontFamily ?? "monument",
    fontWeight: props.$fontWeight ?? "book",
    lineHeight: props.$lineHeight,
    textTransform: props.$textTransform,
    maxWidth: props.$maxWidth,
    textAlign: props.$textAlign,
    underline: props.$underline,
  }),
}));

const createLinkStyles = (props: LinkProps) => {
  const {
    color = "black",
    fontSize = "sm",
    fontFamily = "body",
    fontWeight,
    maxWidth,
    textTransform,
    lineHeight,
  } = props;
  return {
    background: "none !important",
    color: Theme.color(color),
    fontSize: Theme.fontSize(fontSize),
    fontWeight: Theme.fontWeight(fontWeight),
    fontFamily: Theme.fontFamily(fontFamily),
    lineHeight: Theme.lineHeight(lineHeight),
    maxWidth: maxWidth,
    border: "none",
    padding: "0 !important",
    outline: "none",
    textDecoration: props.underline ? "underline" : "none",
    textTransform: textTransform,
    cursor: "pointer",
    "&:hover": {
      color: Theme.color(`${color}900`),
      textDecoration: props.underline ? "none" : "underline",
    },
    "&:focus": {
      color: Theme.color(`${color}900`),
      textDecoration: props.underline ? "none" : "underline",
    },
  };
};
