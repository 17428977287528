import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type SEOProps = {
  description?: string;
  lang?: string;
  meta?: ConcatArray<
    | {
        property: string;
        content: string;
      }
    | {
        name: string;
        content: string;
      }
  >;
  title: string;
};

export const SEO = ({ description = "", lang = "en", meta = [], title }: SEOProps) => {
  const { site } = useStaticQuery<QueryTypes>(SEOStaticQuery);

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: site.siteMetadata?.siteUrl,
        },
        {
          name: `og:image`,
          content: site.siteMetadata?.image,
        },
        {
          name: `og:image:url`,
          content: site.siteMetadata?.image,
        },
        {
          name: `og:image:secure_url`,
          content: site.siteMetadata?.image,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]
        .filter((it) => it.content)
        .concat(meta)}
    />
  );
};

type QueryTypes = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      image: string;
      siteUrl: string;
    };
  };
};

const SEOStaticQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        image
        siteUrl
      }
    }
  }
`;
