import styled, { css } from "styled-components";
import { Theme } from "../core/app.theme";

const baseInputCss = css<{
  error?: boolean;
}>`
  font-size: var(--text-sm);
  font-weight: 400;
  color: var(--color-icywaters);
  border: 1px solid ${({ error }) => (error ? "var(--color-ghostgumgrey100)" : "var(--color-ghostgumgrey100)")};
  border-radius: ${Theme.radius("lg")};
  width: 100%;
  height: 36px;
  line-height: normal;
  background-color: white;
  padding: 8px 10px;
  outline: none;
  &:hover:enabled,
  &:focus:enabled {
    border-color: var(--color-ghostgumgrey900);
  }
`;

export const Input = styled.input<{ error?: boolean }>`
  ${baseInputCss}
`;

export const TextArea = styled.textarea<{ error?: boolean }>`
  ${baseInputCss}
  min-height: 300px;
`;
