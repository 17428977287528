import * as React from "react";
import { Box } from "../../../atoms/box";
import { Theme } from "../../../core/app.theme";
import { SystemPosition, SystemSpace } from "../../../core/styled-system";

export type SectionProps = {
  children?: React.ReactNode;
  backgroundColor?: Theme.ColorScheme;
  seamless?: boolean;
  maxWidth?: Theme.SizeContainer;
} & SystemSpace &
  SystemPosition;

export const Section = (props: SectionProps) => {
  const { maxWidth = "lg", py = props.seamless ? 0 : "2xl", px = "md", backgroundColor = "transparent" } = props;
  return (
    <Box as={"section"} backgroundColor={backgroundColor}>
      <Box m={"0 auto"} w={"100%"} py={py} px={px} maxW={maxWidth} {...props}>
        {props.children}
      </Box>
    </Box>
  );
};
