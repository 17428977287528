import * as React from "react";
import { OptionalResponsiveValue, Space } from "../../../core/sprinkles.css";
import { AlignX, alignXToFlexAlign, AlignY, alignYToFlexAlign } from "../../../core/utils/align";
import { Box, BoxProps } from "../../atoms";

type Props = {
  space?: OptionalResponsiveValue<Space>;
  direction?: BoxProps["flexDirection"];
  alignX?: OptionalResponsiveValue<AlignX>;
  alignY?: OptionalResponsiveValue<AlignY>;
  children: React.ReactNode;
} & Omit<BoxProps, "flexDirection" | "display" | "gap">;

export const Stack = (props: Props) => {
  const { space, direction = "column", alignX, alignY, children, ...delegated } = props;
  return (
    <Box
      display={"flex"}
      flexDirection={direction}
      alignItems={alignXToFlexAlign(alignX)}
      justifyContent={alignYToFlexAlign(alignY)}
      gap={space}
      {...delegated}
    >
      {children}
    </Box>
  );
};
