import * as React from "react";
import { Atoms, atoms } from "../../../core/atom";

type ImageProps = {
  src?: string;
  alt?: string;
} & Atoms;

export const Image = ({ src, alt, ...delegated }: ImageProps) => {
  const atomClass = atoms({ ...delegated });
  return <img src={src} alt={alt} style={{ width: "100%" }} className={atomClass} />;
};
