import * as React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useEmailClient } from "../../client/emailjs.client";
import { buttonBaseCss } from "../../components/atoms/button";
import { Text } from "../../components/atoms/typography";
import { Theme } from "../../components/core/app.theme";
import { Responsive } from "../../components/gls/responsive";
import { VerticalSpacer } from "../../components/gls/spacer";
import { Input } from "../../components/molecules/input-field";
import { Section } from "../../components/templates/page/section/section";

export const FooterSectionSubscribe = () => {
  const { register, handleSubmit, reset } = useForm<{ email: string }>();
  const { onSubmit } = useEmailClient({ onReset: reset });
  const onSubmitForm = (data: { email: string }) =>
    onSubmit({
      email: data.email,
      message: `New subscription from ${data.email}`,
      name: "n/a",
      subject: "New Subscriber",
    });
  return (
    <Section backgroundColor={"icywaters"} py={"md"}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Text colorScheme={"white"} fontWeight={"light"}>
          Subscribe to get updates from H2FA
        </Text>
        <VerticalSpacer space={"sm"} />
        <Responsive style={{ maxWidth: 700 }}>
          <FieldWrapper>
            <TextInputField id={"email"} label={"Email"} type={"email"} required {...register("email")} />
          </FieldWrapper>
          <Button>Subscribe</Button>
        </Responsive>
      </form>
    </Section>
  );
};

const TextInputField = React.forwardRef(
  (props: { label: string } & React.InputHTMLAttributes<HTMLInputElement>, ref?: any) => {
    const { label } = props;
    return (
      <Responsive horizontal={{ verticalAlign: "center" }}>
        <Label id={props.id} htmlFor={props.id} children={label} />
        <Input {...props} ref={ref} />
      </Responsive>
    );
  }
);

const FieldWrapper = styled.div({
  flexGrow: 1,
});

const Label = styled.label`
  font-size: var(--text-sm);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  color: white;
`;

const Button = styled.button({
  ...buttonBaseCss,
  color: Theme.color("primary"),
  backgroundColor: Theme.color("white"),
  borderColor: "transparent",
  "&:focus:enabled": {
    borderColor: Theme.color("ghostgumgrey"),
    backgroundColor: Theme.color("white800"),
  },
  "&:hover:enabled": {
    borderColor: Theme.color("ghostgumgrey"),
    backgroundColor: Theme.color("white800"),
  },
  "&:active:enabled": {
    borderColor: Theme.color("ghostgumgrey"),
    backgroundColor: Theme.color("white800"),
  },
});
