import * as React from "react";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { MediaCentreArticleTemplate } from "../templates/media-centre-article/media-centre-article.template";

type TemplateProps = {
  data: {
    page: {
      uid: string;
      data: {
        title: { text: string };
        description: {
          richText: any;
        };
        date: string;
        hero_image: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
};

const MediaCentreArticlePage = (props: never) => {
  const { data }: TemplateProps = props;
  if (!data) return null;
  const { title, hero_image, description, date } = data.page.data;
  return (
    <MediaCentreArticleTemplate
      slug={data.page.uid}
      title={title.text}
      date={date}
      description={description.richText}
      heroImage={hero_image.gatsbyImageData}
    />
  );
};

export const query = graphql`
  query ($id: String) {
    page: prismicMediaCentreArticlePage(id: { eq: $id }) {
      _previewable
      uid
      data {
        title {
          text
        }
        description {
          richText
        }
        hero_image {
          gatsbyImageData(placeholder: BLURRED, height: 450)
        }
        date(formatString: "DD MMMM, YYYY")
      }
    }
  }
`;

export default withPrismicPreview(MediaCentreArticlePage);
