import React from "react";
import styled from "styled-components";

type FadeInProps = {
  duration?: number;
  delay?: number;
};

export const FadeIn = ({ duration = 300, delay = 0, children }: FadeInProps & { children: React.ReactNode }) => (
  <Wrapper duration={duration} delay={delay}>
    {children}
  </Wrapper>
);

export const TransitionEaseIn = (props: { when?: boolean; children: React.ReactNode }) => (
  <div style={{ opacity: props.when ? 1 : 0, transition: "all 0.3s ease-in" }}>{props.when && props.children}</div>
);

const Wrapper = styled.div<FadeInProps>((props) => ({ ...fadeInCss({ ...props }) }));

export const fadeInCss = ({ duration = 300, delay = 0 }: FadeInProps = {}) => ({
  ["@keyframes fadeIn"]: {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  animationDuration: `${duration}ms`,
  animationDelay: `${delay}ms`,
  ["@media (prefers-reduced-motion: no-preference)"]: {
    animationName: "fadeIn",
    animationFillMode: "backwards",
  },
});
