import * as React from "react";
import styled from "styled-components";
import { Theme, theme } from "../core/app.theme";
import { layout, SystemLayout } from "../core/styled-system";
import { HorizontalProps, VerticalProps } from "./gls-base";
import { createHorizontalProperties } from "./horizontal";
import { createVerticalProperties } from "./vertical";

type ResponsiveProps = {
  horizontal?: HorizontalProps;
  vertical?: VerticalProps;
} & SystemLayout;

export const Responsive = styled.div<ResponsiveProps>((props) => ({
  ...layout({ ...props }),
  [`@media (min-width: 0) and (max-width: ${theme.breakpoints["sm"]}px)`]: {
    ...createVerticalProperties({ ...props.vertical }),
    ...layout({ ...props.vertical }),
  },
  [`@media (min-width: ${theme.breakpoints.sm + 1}px)`]: {
    ...createHorizontalProperties({ ...props.horizontal }),
    ...layout({ ...props.horizontal }),
  },
}));

export const MaxWidth = ({ maxWidth, children }: { maxWidth: Theme.SizeContainer; children?: React.ReactNode }) => (
  <div style={{ maxWidth: Theme.sizeContainer(maxWidth), width: "100%" }} children={children} />
);
