import { send } from "emailjs-com";
import { useState } from "react";

export const useEmailClient = (config: { onReset: () => any }) => {
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState<string | undefined>();
  const onSubmit = async (data: { subject: string; name: string; email: string; message: string }) => {
    try {
      setSubmitting(true);
      await sendMessage({
        subject: data.subject,
        email: data.email,
        name: data.name,
        message: data.message,
      });
      setResult("Your message has been sent successfully!");
      config.onReset();
    } catch (e) {
      setResult("An error has occurred, please try again later!");
    } finally {
      setSubmitting(false);
    }
  };
  return {
    onSubmit,
    result,
    setResult,
    submitting,
  };
};

const sendMessage = (data: { subject: string; name: string; email: string; message: string }) =>
  send(
    "service_92fj5vc",
    "template_bvb344y",
    {
      subject: data.subject,
      from_email: data.email,
      from_name: data.name,
      message: data.message,
    },
    "user_RII4qbKqhJP0EgOr122zr"
  );
