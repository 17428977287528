import React from "react";
import styled from "styled-components";
import { Theme } from "../core/app.theme";
import { composeSystem, layout } from "../core/styled-system";
import { _processCrossAxisAlign, _processSizing, HorizontalProps } from "./gls-base";

export const Horizontal = styled.div<HorizontalProps>((props) => ({
  ...createHorizontalProperties({ ...props }),
  ...composeSystem(layout(props)),
}));

export const createHorizontalProperties = ({
  space = "md",
  sizing,
  crossAxisAlign,
  verticalAlign,
  horizontalAlign,
  reverse,
}: HorizontalProps) => {
  const properties: React.CSSProperties | any = {
    display: "flex",
    flexDirection: reverse ? "row-reverse" : "row",
    ..._processSizing(sizing),
    ..._processCrossAxisAlign(crossAxisAlign),
  };
  if (space !== 0) {
    if (reverse) {
      properties["&>*"] = {
        marginLeft: `${Theme.space(space)} !important`,
      };
      properties["&>*: last-child"] = {
        marginLeft: `0px !important`,
      };
    } else {
      properties["&>*"] = {
        marginRight: `${Theme.space(space)} !important`,
      };
      properties["&>*: last-child"] = {
        marginRight: `0px !important`,
      };
    }
  }
  horizontalAlign === "left" && (properties["justifyContent"] = reverse ? "flex-end" : "flex-start");
  horizontalAlign === "center" && (properties["justifyContent"] = "center");
  horizontalAlign === "right" && (properties["justifyContent"] = reverse ? "flex-start" : "flex-end");
  horizontalAlign === "space-around" && (properties["justifyContent"] = "space-around");
  horizontalAlign === "space-between" && (properties["justifyContent"] = "space-between");
  verticalAlign === "top" && (properties["alignItems"] = "flex-start");
  verticalAlign === "center" && (properties["alignItems"] = "center");
  verticalAlign === "bottom" && (properties["alignItems"] = "flex-end");
  verticalAlign === "baseline" && (properties["alignItems"] = "baseline");
  return properties;
};
