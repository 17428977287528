import React from "react";
import styled from "styled-components";
import { Theme } from "../core/app.theme";
import { composeSystem, layout } from "../core/styled-system";
import { _processCrossAxisAlign, _processSizing, VerticalProps } from "./gls-base";

export const Vertical = styled.div<VerticalProps>((props) => ({
  ...createVerticalProperties({ ...props }),
  ...composeSystem(layout(props)),
}));

export const createVerticalProperties = ({
  space = "md",
  sizing,
  crossAxisAlign,
  verticalAlign,
  horizontalAlign,
}: VerticalProps) => {
  const properties: React.CSSProperties | any = {
    display: "flex",
    flexDirection: "column",
    ..._processSizing(sizing),
    ..._processCrossAxisAlign(crossAxisAlign),
  };
  if (space !== 0) {
    properties["&>*"] = {
      marginBottom: `${Theme.space(space)} !important`,
    };
    properties["&>*: last-child"] = {
      marginBottom: `0px !important`,
    };
  }
  verticalAlign === "center" && (properties["justifyContent"] = "center");
  verticalAlign === "bottom" && (properties["justifyContent"] = "flex-end");
  horizontalAlign === "left" && (properties["alignItems"] = "flex-start");
  horizontalAlign === "center" && (properties["alignItems"] = "center");
  horizontalAlign === "right" && (properties["alignItems"] = "flex-end");
  return properties;
};
