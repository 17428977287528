import * as React from "react";
import styled from "styled-components";
import { FadeIn } from "../../components/atoms/animation";
import { Theme } from "../../components/core/app.theme";
import { FooterLinksSection } from "./footer-links.section";
import { FooterPartnersSection } from "./footer-partners.section";
import { FooterSectionSubscribe } from "./footer-subscribe.section";

export const PageFooter = () => (
  <Footer>
    <FadeIn duration={2000}>
      <FooterPartnersSection />
      <FooterSectionSubscribe />
      <FooterLinksSection />
    </FadeIn>
  </Footer>
);

const Footer = styled.footer({
  margin: "0 auto",
  backgroundColor: Theme.color("background"),
  width: "100%",
  [Theme.media("lg")]: {
    position: "sticky",
    bottom: 0,
  },
});
