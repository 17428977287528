import * as React from 'react';
import "./src/fonts/typography.css";

import "@fontsource/libre-franklin/100.css";
import "@fontsource/libre-franklin/200.css";
import "@fontsource/libre-franklin/300.css";
import "@fontsource/libre-franklin/400.css";
import "@fontsource/libre-franklin/500.css";
import "@fontsource/libre-franklin/600.css";
import "@fontsource/libre-franklin/700.css";
import "@fontsource/libre-franklin/800.css";
import "@fontsource/libre-franklin/900.css";

import { PrismicProvider } from '@prismicio/react';
import { linkResolver } from './src/cms/LinkResolver';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import PageMediaCentreArticleTemplate from './src/pages/{PrismicMediaCentreArticlePage.url}';
import { Link } from 'gatsby';

export const wrapRootElement = ({ element }) => (
  <PrismicProvider internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}>
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            media_centre_article: PageMediaCentreArticleTemplate,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  </PrismicProvider>
);
