import { PrismicRichText } from "@prismicio/react";
import * as prismicT from "@prismicio/types";
import * as React from "react";
import { Heading, Paragraph, Link, Atoms, Image } from "../design-system";

type Props = {
  field?: RichTextField;
  options?: {
    heading?: Atoms;
    text?: Atoms;
    link?: Atoms;
    image?: Atoms;
  };
};

export type RichTextField = prismicT.RichTextField;

export const RichText = ({
  field,
  options = {
    heading: {
      mb: "xl",
    },
    text: {
      mb: "sm",
    },
    link: {
      mb: "sm",
    },
    image: {
      mb: "sm",
    },
  },
}: Props) => {
  return (
    <PrismicRichText
      field={field}
      components={{
        heading1: (props) => (
          <Heading as={"h1"} fontSize={{ mobile: "lg", tablet: "xl", desktop: "xl" }} {...options.heading}>
            {props.children}
          </Heading>
        ),
        heading2: ({ children }) => (
          <Heading as={"h2"} fontSize={{ mobile: "md", tablet: "lg", desktop: "lg" }} {...options.heading}>
            {children}
          </Heading>
        ),
        heading3: ({ children }) => (
          <Heading as={"h3"} fontSize={{ mobile: "sm", tablet: "md", desktop: "md" }} {...options.heading}>
            {children}
          </Heading>
        ),
        heading4: ({ children }) => (
          <Heading as={"h4"} fontSize={"sm"} {...options.heading}>
            {children}
          </Heading>
        ),
        heading5: ({ children }) => (
          <Heading as={"h5"} fontSize={"sm"} {...options.heading}>
            {children}
          </Heading>
        ),
        heading6: ({ children }) => (
          <Heading as={"h6"} fontSize={"sm"} {...options.heading}>
            {children}
          </Heading>
        ),
        hyperlink: (props) => (
          <Link
            fontWeight={"normal"}
            href={props.node.data.url ?? ""}
            target={"_blank"}
            underline={true}
            {...options.link}
          >
            {props.children}
          </Link>
        ),
        image: (props) => <Image src={props.node.url} alt={props.node.alt ?? undefined} {...options.image} />,
        paragraph: ({ children }) => (
          <Paragraph fontSize={"sm"} {...options.text} {...options.text}>
            {children}
          </Paragraph>
        ),
      }}
    />
  );
};
