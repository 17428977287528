import * as React from "react";
import styled from "styled-components";
import { PageFooter } from "../../../_depracated/footer/footer";
import { fadeInCss } from "../../atoms/animation";
import { Theme, theme, themeCssVariable } from "../../core/app.theme";
import { GlobalStyle } from "../../core/global-style";
import { PageHeader } from "../header/header";
import { SEO } from "./seo";

type Props = {
  title: string;
  children: React.ReactNode;
  backgroundColor?: Theme.ColorScheme;
  isFooterEnabled?: boolean;
};

export const Page = ({ title, children, backgroundColor = "ghostgumwhite600" }: Props) => (
  <>
    <HydrogenGlobalStyle backgroundColor={Theme.color(backgroundColor)} />
    <SEO title={title} />
    <PageRoot>
      <PageHeader />
      <PageBody backgroundColor={Theme.color(backgroundColor)}>{children}</PageBody>
      <PageFooter />
    </PageRoot>
  </>
);

const HydrogenGlobalStyle = ({ backgroundColor }: { backgroundColor: Theme.ColorScheme }) => (
  <GlobalStyle
    rootId="___gatsby"
    body={{ fontFamily: theme.fonts.body, backgroundColor: backgroundColor }}
    cssVariables={themeCssVariable}
  />
);

const PageRoot = styled.div.attrs({
  "data-comment": "page-root",
})({
  height: "100%",
  width: "100%",
  display: "flex",
  flex: "1",
  flexDirection: "column",
});

const PageBody = styled.main<{ backgroundColor?: string }>((props) => ({
  flexBasis: "auto",
  flexShrink: 0,
  flex: "1 1 auto",
  flexDirection: "column",
  backgroundColor: props.backgroundColor,
  zIndex: theme.zIndices.pageBody,
  position: "relative",
  ...fadeInCss(),
}));
