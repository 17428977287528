import * as React from "react";
import { Atoms } from "../../../core/atom";
import { Box, BoxProps } from "../box/box";

export type TypographyProps = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "div" | "span";
  size?: BoxProps["fontSize"];
  children?: React.ReactNode;
} & Omit<Atoms, "fontSize"> &
  React.HTMLAttributes<HTMLHeadingElement>;

export const Heading = ({
  as = "h1",
  size = "xl",
  fontFamily = "primary",
  fontWeight = "bold",
  lineHeight = "normal",
  letterSpacing = "normal",
  className,
  children,
  ...delegated
}: TypographyProps) => (
  <Box
    as={as}
    fontSize={size}
    fontFamily={fontFamily}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    letterSpacing={letterSpacing}
    className={className}
    {...delegated}
  >
    {children}
  </Box>
);

export type TextProps = {
  as?: "p" | "div" | "span";
  size?: BoxProps["fontSize"];
  children?: React.ReactNode;
} & Omit<Atoms, "fontSize"> &
  React.HTMLAttributes<HTMLDivElement>;

export const Text = ({
  as = "div",
  size = "sm",
  fontFamily = "primary",
  fontWeight = "normal",
  letterSpacing = "normal",
  lineHeight = "base",
  children,
  className,
  ...delegated
}: TextProps) => (
  <Box
    as={as}
    fontSize={size}
    fontFamily={fontFamily}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    letterSpacing={letterSpacing}
    className={className}
    {...delegated}
  >
    {children}
  </Box>
);

export const Paragraph = ({
  as = "p",
  size = "sm",
  fontFamily = "primary",
  fontWeight = "normal",
  letterSpacing = "normal",
  lineHeight = "base",
  children,
  className,
  ...delegated
}: TextProps) => (
  <Box
    as={as}
    fontSize={size}
    fontFamily={fontFamily}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    letterSpacing={letterSpacing}
    className={className}
    {...delegated}
  >
    {children}
  </Box>
);
