import * as React from "react";
import styled from "styled-components";
import { Theme } from "../core/app.theme";

/**
 * Icons
 *
 * Notes to designers:
 * - We draw at 24px, this matches the defaultSize here.
 * - Create a `.svg` file with the contents as shown below and edit in your editor of choice (I use inkscape)
 * - After editing the file in your editor you might need to clean some gunk (e.g. inkscape meta data)
 *
 * <svg width="24" height="24">
 *  // Paste your contents here
 * </svg>
 *
 * More Tips:
 * - If the icon doesn't have a nice aspect ratio feel free to tweak the viewBox to be exactly what you want
 * - `display:block` to prevent weird sizing issues regarding the parent's line height
 */

export type IconProps = {
  color?: Theme.ColorScheme;
  size?: number;
  viewBox?: string;
  action?: {
    as?: "a" | "button";
    href?: string;
    target?: string;
    onClick?: () => any;
    color?: string;
  };
} & React.SVGProps<SVGSVGElement>;

const withIcon =
  (component: any): React.FunctionComponent<IconProps> =>
  (props: IconProps) => {
    const getType = () => props.action?.as ?? "svg";
    const { action, color, size, ...delegated } = props;
    const svg = (
      <svg
        role={"presentation"}
        viewBox={props.viewBox ?? "0 0 24 24"}
        width={props.size ?? 24}
        height={props.size ?? 24}
        fill={props.color ?? Theme.color("white")}
        children={component}
        {...delegated}
      />
    );
    return (
      <>
        {getType() === "svg" && svg}
        {getType() === "button" && (
          <ActionableIcon
            as={"button"}
            type={"button"}
            onClick={props.action?.onClick}
            color={props.action?.color ?? Theme.color("white700")!}
            children={svg}
          />
        )}
        {getType() === "a" && (
          <ActionableIcon
            as={"a"}
            href={props.action?.href}
            target={props.action?.target}
            onClick={props.action?.onClick}
            color={props.action?.color ?? Theme.color("white700")!}
            children={svg}
          />
        )}
      </>
    );
  };

const ActionableIcon = styled.a<{ color: string }>((props) => ({
  cursor: "pointer",
  display: "block",
  padding: 0,
  margin: 0,
  border: "none",
  backgroundColor: "transparent",
  outline: "none",
  ":hover": {
    svg: {
      fill: props.color,
    },
  },
  ":focus": {
    svg: {
      fill: props.color,
    },
  },
}));

export const IconClose = withIcon(
  <path d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z" />
);

export const IconLinkedIn = withIcon(
  <path d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 002.882 0z" />
);

export const IconEmail = withIcon(
  <path d="M0 5.828v12.453l8.633-5.894zm0 0M14.188 13.281L12 14.945l-2.188-1.664-9.03 6.168h22.437zm0 0M15.367 12.387L24 18.28V5.828zm0 0M.64 4.55l.145.11L12 13.18l11.215-8.52.144-.11zm0 0" />
);

export const IconInstagram = withIcon(
  <>
    <path d="m12.004 5.838c-3.403 0-6.158 2.758-6.158 6.158 0 3.403 2.758 6.158 6.158 6.158 3.403 0 6.158-2.758 6.158-6.158 0-3.403-2.758-6.158-6.158-6.158zm0 10.155c-2.209 0-3.997-1.789-3.997-3.997s1.789-3.997 3.997-3.997 3.997 1.789 3.997 3.997c.001 2.208-1.788 3.997-3.997 3.997z" />
    <path d="m16.948.076c-2.208-.103-7.677-.098-9.887 0-1.942.091-3.655.56-5.036 1.941-2.308 2.308-2.013 5.418-2.013 9.979 0 4.668-.26 7.706 2.013 9.979 2.317 2.316 5.472 2.013 9.979 2.013 4.624 0 6.22.003 7.855-.63 2.223-.863 3.901-2.85 4.065-6.419.104-2.209.098-7.677 0-9.887-.198-4.213-2.459-6.768-6.976-6.976zm3.495 20.372c-1.513 1.513-3.612 1.378-8.468 1.378-5 0-7.005.074-8.468-1.393-1.685-1.677-1.38-4.37-1.38-8.453 0-5.525-.567-9.504 4.978-9.788 1.274-.045 1.649-.06 4.856-.06l.045.03c5.329 0 9.51-.558 9.761 4.986.057 1.265.07 1.645.07 4.847-.001 4.942.093 6.959-1.394 8.453z" />
  </>
);

export const IconChevronDown = withIcon(
  <>
    <path
      d="M6.744 8.744a1.053 1.053 0 000 1.49l4.547 4.557a1 1 0 001.416 0l4.55-4.558a1.051 1.051 0 10-1.488-1.488l-3.77 3.776-3.768-3.776a1.051 1.051 0 00-1.487 0z"
      fillRule="evenodd"
    />
  </>
);
