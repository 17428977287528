import * as React from "react";
import styled from "styled-components";
import { Theme } from "../core/app.theme";
import {
  composeSystem,
  layout,
  space,
  SystemColor,
  SystemLayout,
  SystemSpace,
  SystemTypography,
  typography,
} from "../core/styled-system";
import { Vertical } from "../gls/vertical";

export type TypographyProps = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "div" | "span";
  colorScheme?: Theme.ColorScheme;
  children?: React.ReactNode;
} & SystemSpace &
  SystemLayout &
  SystemColor &
  SystemTypography;

export const Heading = styled.h1<TypographyProps>((props) => ({
  ...createHeadingStyles(props),
}));

export const Text = styled.div<TypographyProps>((props) => ({
  ...createTextStyles(props),
}));

export const Paragraph = styled.p<TypographyProps>((props) => ({
  ...createTextStyles(props),
}));

export const Paragraphs = (props: { children: React.ReactNode }) => <Vertical space={"sm"}>{props.children}</Vertical>;

const createHeadingStyles = (props: TypographyProps) => ({
  margin: 0,
  padding: 0,
  color: Theme.color(props.colorScheme ?? "icywaters"),
  ...composeSystem(
    typography({
      fontFamily: "heading",
      fontSize: "xl",
      fontWeight: "bold",
      lineHeight: "normal",
      letterSpacing: "normal",
      ...props,
    }),
    space(props),
    layout(props)
  ),
});

const createTextStyles = (props: TypographyProps) => ({
  margin: 0,
  padding: 0,
  color: Theme.color(props.colorScheme ?? "icywaters"),
  ...composeSystem(
    typography({
      fontSize: "sm",
      fontFamily: "body",
      lineHeight: "base",
      letterSpacing: "normal",
      ...props,
    }),
    space(props),
    layout(props)
  ),
});
