import * as React from "react";
import styled from "styled-components";
import { IconClose } from "../../atoms/icon";
import { LinkInternal, LinkProps } from "../../atoms/link";
import { Theme } from "../../core/app.theme";
import { Vertical } from "../../gls/vertical";

export const SidebarLink = (props: LinkProps & { to: string; onClick: () => any }) => (
  <LinkInternal to={props.to} $color={"ghostgumwhite"} $maxWidth={200} $lineHeight={"tall"} onClick={props.onClick}>
    {props.children}
  </LinkInternal>
);

export const SidebarContent = styled(Vertical)({
  height: "100%",
  padding: `${Theme.space("md")}`,
});

export const SidebarCloseButton = (props: { onClick: () => any }) => (
  <SideBarCloseButtonWrapper>
    <IconClose
      onClick={props.onClick}
      action={{
        as: "button",
        onClick: props.onClick,
      }}
      aria-label={"Close navigation"}
    />
  </SideBarCloseButtonWrapper>
);

const SideBarCloseButtonWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
});
