import { Theme } from "../core/app.theme";
import { SystemLayout } from "../core/styled-system";

export type CrossAxisAlignProp = {
  /**
   * Allows an item to override its alignment in the parents cross axis
   * e.g.
   * - allows an item to stretch horizontally in a Vertical(horizontalAlign='left')
   * - allows an item to stretch vertically in a Horizontal(verticalAlign='top')
   */
  crossAxisAlign?: "start" | "center" | "end" | "stretch";
};

export type Sizing = "content" /** default */ | "stretch" /** Same as `1` */ | number;
/** A flex ratio */

export type VerticalProps = {
  space?: Theme.Space;
  sizing?: Sizing;
  verticalAlign?: "center" | "bottom";
  horizontalAlign?: "left" | "center" | "right";
} & CrossAxisAlignProp &
  SystemLayout;

export type HorizontalProps = {
  space?: Theme.Space;
  sizing?: Sizing;
  /** Child alignment in vertical axis */
  verticalAlign?: "stretch" /** default */ | "top" | "center" | "bottom" | "baseline";
  /** Child alignment in horizontal axis */
  horizontalAlign?:
    | "left" /** default if `reverse = false` */
    | "center"
    | "right" /** default if `reverse = true` */
    | "space-around"
    | "space-between";
  reverse?: boolean;
} & CrossAxisAlignProp &
  SystemLayout;

export function _processSizing(fx: Sizing = "content") {
  return fx == "content"
    ? {
        flexBasis: "auto",
      }
    : fx == "stretch"
    ? { flex: 1, flexBasis: "auto" }
    : { flex: fx, flexBasis: "auto" };
}

export function _processCrossAxisAlign(align?: CrossAxisAlignProp["crossAxisAlign"]) {
  if (align == null) {
    return {};
  }
  return align == "start"
    ? { alignSelf: "flex-start" }
    : align == "center"
    ? { alignSelf: "center" }
    : align == "end"
    ? { alignSelf: "flex-end" }
    : { alignSelf: "stretch" };
}
